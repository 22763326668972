import IModelFactory from "@/services/IModelFactory";
import { INVALID_ID } from "@/models/Model";
import { ClassLevel } from "@/models/ClassLevel";
import { injectable } from "inversify";
import Student from "@/models/Student";
import moment from "moment";
import User from "@/models/User";
import { SchoolYear } from "@/models/SchoolYear";
import SchoolSubject from "@/models/SchoolSubject";
import Teacher from "@/models/Teacher";
import SubjectPart from "@/models/SubjectPart";
import { Subject } from "@/models/Subject";
import Skill from "@/models/Skill";
import SkillType from "@/models/SkillType";
import { Class } from "@/models/Class";
import ActivityType from "@/models/ActivityType";
import Role from "@/models/Role";
import ClassSubjectPart from "@/models/ClassSubjectPart";
import Activity from "@/models/Activity";
import { SchoolYearState } from "@/enums/SchoolYearState";
import { GradingModes } from "@/enums/GradingModes";
import { SchoolSubjectType } from "@/enums/SchoolSubjectType";
import { ReqLevel } from "@/enums/ReqLevel";
import { NotificationMessage } from "@/events/NotificationMessage";
import { NotificationType } from "@/events/NotificationType";
import IState from "@/models/apiTypes/IState";
import i18n from "@/i18n";
import Link from "@/models/vos/Link";
import IModelStatus from "@/models/apiTypes/IModelStatus";
import RatingItem from "@/models/vos/RatingItem";
import Level from "@/models/Level";
import { ActivityTypeCategory } from "@/enums/ActivityTypeCategory";
import { ActivityTypeRatingMode } from "@/enums/ActivityTypeRatingMode";
import { TeachingStyle } from "@/enums/TeachingStyle";
import {RatingDisplayMode} from "@/enums/RatingDisplayMode";

@injectable()
export default class ModelFactory implements IModelFactory {
  private _gradesAsRatingItems: RatingItem<number>[] = [];
  private _socialRanksAsRatingItems: RatingItem<string>[] = [];

  createStudent(
    id: string = INVALID_ID,
    first_name: string = "",
    last_name = "",
    birthday = "",
    birthplace = "",
    entry_date = moment().format("YYYY-MM-DD"),
    user = this.createUser(),
    exit_date = "",
    currentClass = null
  ): Student {
    return {
      id,
      sibank_id: INVALID_ID,
      first_name,
      last_name,
      birthday,
      birthplace,
      entry_date,
      exit_date,
      currentClass,
    };
  }

  createUser(
    id: string = INVALID_ID,
    name: string = "",
    email: string = "",
    is_admin: boolean = false,
    is_system: boolean = false,
    active: boolean = false
  ): User {
    return { id, email: "", name: "", active: false, is_admin, is_system };
  }

  createSchoolYear(
    id: string = INVALID_ID,
    year: number = new Date().getFullYear(),
    is_released: boolean = false,
    start: string = "",
    end: string = ""
  ): SchoolYear {
    return {
      id,
      year,
      is_released,
      start,
      end,
      state: SchoolYearState.CREATED,
      classLevels: [],
    };
  }

  createSchoolSubject(
    id: string = INVALID_ID,
    denomination: string = ""
  ): SchoolSubject {
    return { id, denomination, type: SchoolSubjectType.COMPULSORY };
  }

  createTeacher(
    id: string = INVALID_ID,
    first_name: string = "",
    last_name = "",
    birthday = "",
    birthplace = "",
    entry_date = moment().format("YYYY-MM-DD"),
    exit_date = ""
  ): Teacher {
    return {
      id,
      first_name,
      last_name,
      birthday,
      entry_date,
      exit_date,
    };
  }

  createSubject(
    id: string = INVALID_ID,
    classLevel: ClassLevel = this.createClassLevel(),
    schoolSubject: SchoolSubject = this.createSchoolSubject()
  ): Subject {
    return {
      id,
      denomination: schoolSubject.denomination,
      classLevel,
      schoolSubject,
      subjectParts: [],
      req_level: ReqLevel.NOT_SET,
      individually_assigned: false,
      class_participation_factor: 0.33,
      exam_factor: 0.33,
      teaching_style: TeachingStyle.CLASS_SUBJECT,
      subject_specific_factor: 0.33,
      hours_per_week: null,
      rating_enabled: true,
      show_req_level_in_report: true,
      available_in_first_term: true,
      available_in_second_term: true,
    };
  }

  createSubjectPart(
    id: string = INVALID_ID,
    subject: Subject = this.createSubject(),
    denomination: string = "",
    description: string = "",
    term: number = 1,
    rank: number = 0
  ): SubjectPart {
    return {
      id,
      subject,
      denomination,
      description,
      rank,
      term,
      rating_enabled: true,
      skillTypes: [],
    };
  }

  createClassLevel(
    id: string = INVALID_ID,
    schoolYear: SchoolYear = this.createSchoolYear(),
    level: number = 1,
    denomination: string = "",
    rating_display_mode: RatingDisplayMode = RatingDisplayMode.DEFAULT,
    amount_stages: number = 4,
    amount_levels: number = 4
  ): ClassLevel {
    return {
      id,
      schoolYear,
      level,
      denomination,
      rating_display_mode,
      amount_stages,
      amount_levels,
      grade_mode: GradingModes.DIF_AND_STAGE,
      skills_enabled: true,
      levels: [],
      amount_soft_skill_stages: 4,
      show_skill_ratings_in_cspr: true,
      show_summed_result_in_cspr: false,
      rate_activities_with_skills: true,
    };
  }

  createClass(
    id: string = INVALID_ID,
    classLevel: ClassLevel = this.createClassLevel(),
    denomination: string = ""
  ): Class {
    return { id, classLevel, denomination };
  }

  createActivityType(
    id: string = INVALID_ID,
    denomination: string = "",
    description: string = ""
  ): ActivityType {
    return {
      id,
      denomination,
      description,
      category: ActivityTypeCategory.SUBJECT_SPECIFIC,
      rating_mode: ActivityTypeRatingMode.OPTIONAL,
    };
  }

  createRole(id: string = INVALID_ID, name: string = ""): Role {
    return { id, name, permissions: [], users: [] };
  }

  createActivity(
    classSubjectPart?: ClassSubjectPart,
    id: string = INVALID_ID,
    activityType: ActivityType = this.createActivityType(),
    denomination: string = "",
    description: string = "",
    due_at: string = ""
  ): Activity {
    return {
      id,
      classSubjectPart,
      activityType,
      denomination,
      description,
      due_at,
      mandatory: false,
    };
  }

  modelStatusToNotificationMessages(
    modelStatus: IModelStatus
  ): NotificationMessage[] {
    let notificationMessages: NotificationMessage[] = [];

    modelStatus.warnings.forEach((state) => {
      notificationMessages = notificationMessages.concat(
        this.getMessagesFromState(state, NotificationType.Warning)
      );
    });
    modelStatus.errors.forEach((state) => {
      notificationMessages = notificationMessages.concat(
        this.getMessagesFromState(state, NotificationType.Error)
      );
    });

    return notificationMessages;
  }

  private getMessagesFromState(
    state: IState,
    type: NotificationType
  ): NotificationMessage[] {
    const messages = [];
    const stateMessage: string = state.message
      ? state.message
      : i18n.t(`states.${state.code}`).toString();
    const links: Link[] = [];
    if (state.relatedModels.length > 0) {
      state.relatedModels.forEach((relatedModel) => {
        links.push({
          text: relatedModel.denomination,
          to: relatedModel.url ? relatedModel.url : undefined,
        });
      });
    }
    messages.push(new NotificationMessage(type, stateMessage, [], links));

    return messages;
  }

  stageToRatingItem(stage: number | null): RatingItem<number> {
    return {
      value: stage ? stage : -1,
      id: stage ? stage.toString() : INVALID_ID,
      icon: "mdi-star",
      text: stage ? stage.toString() : "",
    };
  }

  getStagesAsRatingItems(amount_stages: number): RatingItem<number>[] {
    const stages: RatingItem<number>[] = [];
    for (let i = 1; i <= amount_stages; i++) {
      stages.push(this.stageToRatingItem(i));
    }
    return stages;
  }

  skillToRatingItem(skill: Skill) {
    return {
      value: skill,
      id: skill.id,
      icon: "mdi-star",
      text: skill.description,
      actionText: skill.level.denomination,
    };
  }

  getSkillsAsRatingItems(skills: Skill[]): RatingItem<Skill>[] {
    return skills.map((skill) => this.skillToRatingItem(skill));
  }

  getSkillLevelRank(skill: Skill | null): number {
    return skill && skill.level && skill.level.denomination
      ? skill.level.rank
      : 0;
  }

  getLevelsAsRatingItems(
    levels: Level[] | null | undefined
  ): RatingItem<Level>[] {
    const stages: RatingItem<Level>[] = [];
    if (levels) {
      for (const level of levels) {
        stages.push(this.levelToRatingItem(level));
      }
    }
    return stages;
  }

  levelToRatingItem(level: Level): RatingItem<Level> {
    return {
      id: level.id,
      text: level.denomination,
      value: level,
      icon: "mdi-star",
    };
  }

  getGradesAsRatingItems(): RatingItem<number>[] {
    if (this._gradesAsRatingItems.length < 1) {
      this._gradesAsRatingItems = [
        this.gradeToRatingItem(6),
        this.gradeToRatingItem(5),
        this.gradeToRatingItem(4),
        this.gradeToRatingItem(3),
        this.gradeToRatingItem(2),
        this.gradeToRatingItem(1),
      ];
    }
    return this._gradesAsRatingItems;
  }

  gradeToRatingItem(grade: number): RatingItem<number> {
    return {
      id: grade.toString(),
      text: grade.toString(),
      value: grade,
      icon: "mdi-star",
    };
  }

  getSocialRanksAsRatingItems(): RatingItem<string>[] {
    if (this._socialRanksAsRatingItems.length < 1) {
      this._socialRanksAsRatingItems = [
        this.socialRankToRatingItem("e"),
        this.socialRankToRatingItem("d"),
        this.socialRankToRatingItem("c"),
        this.socialRankToRatingItem("b"),
        this.socialRankToRatingItem("a"),
      ];
    }
    return this._socialRanksAsRatingItems;
  }

  socialRankToRatingItem(rank: string): RatingItem<string> {
    return {
      id: rank.toString(),
      text: rank,
      value: rank,
      icon: "mdi-star",
    };
  }

  getSchoolSubjectDenominationWithType(
    schoolSubject: SchoolSubject | null | undefined
  ) {
    return schoolSubject
      ? `${schoolSubject.denomination} - ${i18n.t(
          `schoolSubjectTypes.${schoolSubject.type}`
        )}`
      : "";
  }

  getSubjectDenominationWithType(subject: Subject | null | undefined) {
    return subject
      ? `${subject.denomination} - ${i18n.t(
          `schoolSubjectTypes.${subject.schoolSubject.type}`
        )} (${subject.req_level})`
      : "";
  }

  getSubjectDenominationWithReqLevel(
    subject: Subject | null | undefined
  ): string {
    return subject && subject.req_level
      ? `${subject.denomination} - ${subject.req_level}`
      : "--/--";
  }

  getIdToolTipText(id: string): string {
    return "#" + id;
  }

  getSchoolSubjectDenominationWithTypeShort(
    schoolSubject: SchoolSubject | null | undefined
  ) {
    const short = schoolSubject
      ? i18n.t(`schoolSubjectTypes.short.${schoolSubject.type}`)
      : "";
    let result = schoolSubject ? schoolSubject.denomination : "";
    if (short) {
      result += `(${short})`;
    }
    return result;
  }
}
