import ISkillDataService from "@/services/ISkillDataService";
import { inject, injectable } from "inversify";
import SkillType from "@/models/SkillType";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import deleteSkillType from "@/graphql/skillTypes/deleteSkillType.graphql";
import createSkillType from "@/graphql/skillTypes/createSkillType.graphql";
import updateSkillType from "@/graphql/skillTypes/updateSkillType.graphql";
import Skill from "@/models/Skill";
import SkillCreateVO from "@/models/vos/SkillCreateVO";

@injectable()
export default class SkillDataService implements ISkillDataService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  deleteSkillType(skillType: SkillType): Promise<boolean> {
    return this.backendApi.apolloMutation("deleteSkillType", deleteSkillType, {
      id: skillType.id,
    });
  }

  saveSkillType(skillType: SkillType, skills: Skill[]): Promise<SkillType> {
    return this.backendApi.apolloMutation<SkillType>(
      "updateSkillType",
      updateSkillType,
      {
        id: skillType.id,
        denomination: skillType.denomination,
        skills: skills.map((skill) => {
          return {
            id: skill.id,
            description: skill.description,
            level_id: skill.level.id,
            enabled: skill.enabled,
          };
        }),
      }
    );
  }

  createSkillType(
    subject_part_id: string,
    denomination: string,
    skills: SkillCreateVO[]
  ): Promise<SkillType> {
    return this.backendApi.apolloMutation<SkillType>(
      "createSkillType",
      createSkillType,
      {
        subject_part_id,
        denomination,
        skills: skills.map((item) => {
          return {
            level_id: item.level.id,
            enabled: item.enabled,
            description: item.description,
          };
        }),
      }
    );
  }
}
