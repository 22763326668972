import IClassSubjectPartReportDataService from "@/services/IClassSubjectPartReportDataService";
import ClassSubjectPartReport from "@/models/ClassSubjectPartReport";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import createClassSubjectPartReport from "@/graphql/classSubjectPartReport/createClassSubjectPartReport.graphql";
import getOrCreateClassSubjectPartReports from "@/graphql/classSubjectPartReport/getOrCreateClassSubjectPartReports.graphql";
import finishSubjectPartReport from "@/graphql/classSubjectPartReport/finishSubjectPartReport.graphql";
import updateSubjectPartReport from "@/graphql/classSubjectPartReport/updateSubjectPartReport.graphql";
import resetSubjectPartReport from "@/graphql/classSubjectPartReport/resetSubjectPartReport.graphql";
import finishClassSubjectPartReports from "@/graphql/classSubjectPartReport/finishClassSubjectPartReports.graphql";
import updateStudentSkill from "@/graphql/classSubjectPartReport/updateStudentSkill.graphql";
import updateStudentSoftSkill from "@/graphql/classSubjectPartReport/updateStudentSoftSkill.graphql";
import StudentSkill from "@/models/StudentSkill";
import StudentSoftSkill from "@/models/StudentSoftSkill";

@injectable()
export default class ClassSubjectPartReportDataService
  implements IClassSubjectPartReportDataService
{
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  createReport(
    student_id: string,
    class_subject_part_id: string
  ): Promise<ClassSubjectPartReport> {
    return this.backendApi.apolloMutation<ClassSubjectPartReport>(
      "createSubjectPartReport",
      createClassSubjectPartReport,
      { student_id, class_subject_part_id }
    );
  }

  finishReport(
    classSubjectPartReport: ClassSubjectPartReport
  ): Promise<ClassSubjectPartReport> {
    return this.backendApi.apolloMutation<ClassSubjectPartReport>(
      "finishSubjectPartReport",
      finishSubjectPartReport,
      {
        id: classSubjectPartReport.id,
      }
    );
  }

  saveStudentSkill(
    id: string,
    skill_id: string,
    stage: number | null,
    excluded_from_rating: boolean
  ): Promise<StudentSkill> {
    return this.backendApi.apolloMutation<StudentSkill>(
      "updateStudentSkill",
      updateStudentSkill,
      {
        id,
        skill_id,
        stage,
        excluded_from_rating,
      }
    );
  }

  saveStudentSoftSkill(
    id: string,
    stage: number | null,
    remark: string,
    grade: number | null,
    excluded_from_rating: boolean
  ): Promise<StudentSoftSkill> {
    return this.backendApi.apolloMutation<StudentSoftSkill>(
      "updateStudentSoftSkill",
      updateStudentSoftSkill,
      {
        id,
        stage,
        remark,
        grade,
        excluded_from_rating,
      }
    );
  }

  getOrCreateSubjectReports(
    classSubjectPartId: string
  ): Promise<ClassSubjectPartReport[]> {
    return this.backendApi.apolloMutation<ClassSubjectPartReport[]>(
      "createSubjectPartReports",
      getOrCreateClassSubjectPartReports,
      { class_subject_part_id: classSubjectPartId }
    );
  }

  finishReports(classSubjectPartId: string): Promise<ClassSubjectPartReport[]> {
    return this.backendApi.apolloMutation<ClassSubjectPartReport[]>(
      "finishClassSubjectPartReports",
      finishClassSubjectPartReports,
      {
        class_subject_part_id: classSubjectPartId,
      }
    );
  }

  saveReport(report: ClassSubjectPartReport): Promise<ClassSubjectPartReport> {
    return this.backendApi.apolloMutation<ClassSubjectPartReport>(
      "updateClassSubjectPartReport",
      updateSubjectPartReport,
      {
        id: report.id,
        grade: report.grade,
        stage: report.stage,
        level_id: report.level?.id ?? null,
        remark: report.remark,
        social_behavior: report.social_behavior,
        work_habit: report.work_habit,
        excluded_from_rating: report.excluded_from_rating,
        performance_refused: report.performance_refused,
      }
    );
  }

  resetReport(report: ClassSubjectPartReport): Promise<ClassSubjectPartReport> {
    return this.backendApi.apolloMutation<ClassSubjectPartReport>(
      "resetClassSubjectPartReport",
      resetSubjectPartReport,
      {
        id: report.id,
      }
    );
  }
}
