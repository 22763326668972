import IStudentReportDataService from "@/services/IStudentReportDataService";
import StudentReport from "@/models/StudentReport";
import SubjectResult from "@/models/SubjectResult";
import { inject, injectable } from "inversify";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import TYPES from "@/types";
import confirmClassSubjectStudentResult from "@/graphql/studentReport/confirmClassSubjectStudentResult.graphql";
import refreshStudentReport from "@/graphql/studentReport/refreshStudentReport.graphql";
import updateStudentReport from "@/graphql/studentReport/updateStudentReport.graphql";
import updateStudentReportType from "@/graphql/studentReport/updateStudentReportType.graphql";
import updateSoftSkillResult from "@/graphql/studentReport/updateSoftSkillResult.graphql";
import updateSubjectResult from "@/graphql/studentReport/updateSubjectResult.graphql";
import createStudentReport from "@/graphql/studentReport/createStudentReport.graphql";
import createStudentReports from "@/graphql/studentReport/createStudentReports.graphql";
import createStudentReportPDF from "@/graphql/studentReport/createStudentReportPDF.graphql";
import { GradingModes } from "@/enums/GradingModes";
import i18n from "@/i18n";
import Document from "@/models/Document";
import SubjectSoftSkillResult from "@/models/SubjectSoftSkillResult";
import { StudentReportType } from "@/enums/StudentReportType";
import IModelStatus from "@/models/apiTypes/IModelStatus";
import { RatingDisplayMode } from "@/enums/RatingDisplayMode";

@injectable()
export default class StudentReportDataService
  implements IStudentReportDataService
{
  protected pointsToGrade = [
    6, 5.3, 5, 4.7, 4.3, 4, 3.7, 3.3, 3, 2.7, 2.3, 2, 1.7, 1.3, 1, 0.7,
  ];

  constructor(
    @inject(TYPES.IGraphQLBackendApi)
    protected backendApi: IGraphQLBackendApi
  ) {}

  confirmClassSubjectStudentResult(
    classSubjectStudentResult: SubjectResult
  ): Promise<SubjectResult> {
    return this.backendApi.apolloMutation(
      "confirmClassSubjectStudentResult",
      confirmClassSubjectStudentResult,
      {
        id: classSubjectStudentResult.id,
      }
    );
  }

  finishStudentReport(studentReport: StudentReport): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "finishStudentReport",
      updateStudentReport,
      {
        id: studentReport.id,
      }
    );
  }

  updateRemark(id: string, remark: string): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "updateStudentReport",
      updateStudentReport,
      { id, remark }
    );
  }

  updateSocialBehavior(
    id: string,
    social_behavior: string | null
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "updateStudentReport",
      updateStudentReport,
      {
        id,
        social_behavior,
      }
    );
  }

  updateTarget(id: string, target: string): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "updateStudentReport",
      updateStudentReport,
      {
        id,
        target,
      }
    );
  }

  updateWorkHabit(
    id: string,
    work_habit: string | null
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "updateStudentReport",
      updateStudentReport,
      {
        id,
        work_habit,
      }
    );
  }

  reportResultByMode(subjectResult: SubjectResult): string {
    let result = "";
    if (subjectResult.excluded_from_rating) {
      result = i18n.t("cannotBeRated").toString();
    } else if (subjectResult.rating_enabled) {
      switch (subjectResult.grade_mode) {
        case GradingModes.DIF_AND_STAGE:
          result = `${subjectResult.level?.denomination ?? ""}`;
          if (subjectResult.rating_display_mode === RatingDisplayMode.DEFAULT) {
            result += ` ${subjectResult.stage ?? ""}`;
          }
          break;
        case GradingModes.GRADES:
          result = `${subjectResult.grade ?? ""}`;
          break;
        default:
          result = "";
      }
    } else {
      result = i18n.t("seeRemarks").toString();
    }

    return result;
  }

  reportCalculatedResultByMode(subjectResult: SubjectResult): string {
    let result = "";
    if (subjectResult.rating_enabled) {
      switch (subjectResult.grade_mode) {
        case GradingModes.DIF_AND_STAGE:
          result = `${subjectResult.level?.denomination ?? ""}`;
          if (subjectResult.rating_display_mode === RatingDisplayMode.DEFAULT) {
            result += ` ${subjectResult.stage ?? ""}`;
          }
          break;
        case GradingModes.GRADES:
          result = `${subjectResult.grade_calculated ?? ""}`;
          break;
        default:
          result = "";
      }
    } else {
      result = i18n.t("seeRemarks").toString();
    }

    return result;
  }

  protected difAndStageToPoints(level: number, stage: number) {
    return level * stage - 1;
  }

  updateResult(
    id: string,
    level_id: string | null,
    stage: number | null,
    grade: number | null,
    studentReportId: string,
    excluded_from_rating: boolean
  ): Promise<SubjectResult> {
    return this.backendApi.apolloMutation(
      "updateSubjectResult",
      updateSubjectResult,
      {
        id,
        level_id,
        stage,
        grade,
        excluded_from_rating,
      }
    );
  }

  createStudentReport(
    school_year_id: string | undefined,
    student_id: string | undefined,
    term: number,
    type: number
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "createStudentReport",
      createStudentReport,
      {
        school_year_id,
        student_id,
        term,
        type,
      }
    );
  }

  createStudentReports(
    school_year_id: string | undefined,
    term: number
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "createStudentReports",
      createStudentReports,
      {
        school_year_id,
        term,
      }
    );
  }

  refreshStudentReport(
    student_report_internal: StudentReport
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "refreshStudentReport",
      refreshStudentReport,
      {
        id: student_report_internal.id,
      }
    );
  }

  createStudentReportPDF(id: string): Promise<Document> {
    return this.backendApi.apolloMutation(
      "createStudentReportPDF",
      createStudentReportPDF,
      {
        id,
      }
    );
  }

  updateAbsences(
    id: string,
    days_absent: number,
    days_unexcused: number
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "updateStudentReport",
      updateStudentReport,
      { id, days_absent, days_unexcused }
    );
  }

  updateSoftSkillResult(
    softSkillResultId: string,
    stage: number | null
  ): Promise<SubjectSoftSkillResult> {
    return this.backendApi.apolloMutation(
      "updateSoftSkillResult",
      updateSoftSkillResult,
      { id: softSkillResultId, stage }
    );
  }

  resetReport(id: string): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "resetStudentReport",
      updateStudentReport,
      { id }
    );
  }

  resetReports(
    report_ids: string[]
  ): Promise<{ studentReports: StudentReport[]; messages?: IModelStatus }> {
    return this.backendApi.apolloMutation(
      "resetStudentReports",
      updateStudentReport,
      { report_ids }
    );
  }

  finishReports(
    report_ids: string[]
  ): Promise<{ studentReports: StudentReport[]; messages?: IModelStatus }> {
    return this.backendApi.apolloMutation(
      "finishStudentReports",
      updateStudentReport,
      { report_ids }
    );
  }

  getReportTypeNameByReport(studentReport: StudentReport) {
    if (
      studentReport.type === StudentReportType.TERM_REPORT &&
      studentReport.term === 2
    ) {
      return i18n.tc("studentReportTypes.final");
    } else {
      return i18n.tc(`studentReportTypes.${studentReport.type}`);
    }
  }

  getReportTypeName(reportType: StudentReportType, term: number | null = null) {
    if (reportType === StudentReportType.TERM_REPORT && term === 2) {
      return i18n.tc("studentReportTypes.final");
    } else {
      return i18n.tc(`studentReportTypes.${reportType}`);
    }
  }

  updateStudentReportType(
    id: string,
    type: StudentReportType
  ): Promise<StudentReport> {
    return this.backendApi.apolloMutation(
      "updateStudentReportType",
      updateStudentReportType,
      {
        id,
        type,
      }
    );
  }
}
